<template>
	<div v-show="show"
		style="background-color: rgba(0,0,0,0.3);width: 100vw;height:100vh;z-index: 2006;position: fixed;top: 0;left: 0;">
		<el-dialog title="报名管理" :visible.sync="show" width="55%">
			<div style="width: 100%;display: flex;align-items: center;justify-content: space-between;">
				<div style="width: 50%;height: 60vh;overflow-y:auto;padding: 20px;font-size: 16px;">
					<div style="margin-bottom: 10px;">已报名人数<span style="color:#277bf5">({{bmList.length}}人)</span></div>

					<div style="width:100%;">
						<div style="min-height: 49px;display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 10px 0;border-bottom: 1px solid #eee;"
							v-for="item in bmList">
							<div style="color: #333;font-size:16px;font-weight: bold;">
								{{item.name?item.name:'报名人员'}}
							</div>
							<div style="font-size: 14px;">
								<span style="margin-right: 10px;">报名时间：</span><span
									style="color:#333;">{{item.cztime}}</span>
							</div>
						</div>
					</div>
				</div>
				<div style="width: 50%;height: 60vh;overflow-y:auto;padding: 20px;font-size: 16px;padding-left: 30px;">
					<div style="margin-bottom: 10px;">未报名人数<span style="color:#277bf5">({{wbmList.length}}人)</span></div>

					<div style="width:100%;">
						<div style="display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 10px 0;border-bottom: 1px solid #eee;"
							v-for="item in wbmList">
							<div style="color: #333;font-size:16px;font-weight: bold;">
								{{item.name?item.name:'报名人员'}}
							</div>
							<div style="font-size: 14px;">
								  <el-button type="success" size="mini" @click="showbm(item)">报名</el-button>
								  <el-button type="warning" size="mini" @click="qingjia(item)">请假</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="show = false">取 消</el-button>
				<el-button type="primary" @click="show = false">确 定</el-button>
			</span>
		</el-dialog>
		
		<!--报名弹窗-->
		<el-dialog title="请确认报名" :visible.sync="showtcbm" width="30%">
			<div style="width: 100%;">
				<div style="margin-top: 10px;font-size: 16px;">
					报名人员：<span style="font-weight: bold;">{{wbmry.name}}</span>
				</div>
				<!-- <div style="display: flex;align-items: center;margin-top: 20px;">
					<div>报名时间：</div>
					<el-date-picker
					      v-model="time"
					      type="datetime"
					      placeholder="选择报名时间"
						  value-format="yyyy-MM-dd HH:mm:ss"
						  size="small">
					</el-date-picker>
				</div> -->
			</div>
		
			<span slot="footer" class="dialog-footer">
				<el-button @click="showtcbm = false">取 消</el-button>
				<el-button type="success" @click="surebm">报 名</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				show: false,
				id: '',
				bmList: [],
				wbmList: [], //未报名人员
				wbmry:'',
				showtcbm:false,
				time:''
			}
		},
		methods: {
			qingjia(item){
				this.$prompt('请输入请假的理由', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /.+/,
					inputErrorMessage: '请输入'
				}).then(({
					value
				}) => {
					this.$post({
						url: '/api/Wymeetingtz/glyqdbm',
						params: {
							type:4,//3参加 4请假
							id: item.id,
							liyou:value
						}
					}).then((res) => {
						this.showtcbm = false
						this.$message.success('操作成功')
						this.getlist()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消操作'
					});
				})
			},
			surebm(){
				this.$post({
					url: '/api/Wymeetingtz/glyqdbm',
					params: {
						type:3,//3参加 4请假
						id: this.wbmry.id
					}
				}).then((res) => {
					this.showtcbm = false
					this.$message.success('报名成功')
					this.getlist()
				})
			},
			showbm(item){
				this.wbmry = item
				this.showtcbm = true	
			},
			getlist() {
				this.$post({
					url: '/api/wymeeting/details',
					params: {
						id: this.id
					}
				}).then((res) => {
					this.bmList = res.bmry
					this.wbmList = res.qbry.filter(o1 => !this.bmList.some(o2 => o1.user_id === o2.user_id));
					this.$parent.getinfo()
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

</style>